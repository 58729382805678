import { takeLatest, fork, all, call, put } from 'redux-saga/effects';
import Auth from 'utils/auth';
import { gql } from '@apollo/client';
import { get } from 'lodash';
import * as types from './constants';
import * as queries from './queries';
import { GLOBAL_ERROR } from '../App/constants';

async function getClient(role = 'TENANT_ADMIN') {
  return Auth.client(role);
}

export function* actionReceiverSaga(props) {
  const { payload, callback, queryString, key, refetchActions, responseQuery, mixPanelAction, spreadPayload } = props;
  try {
    const client = yield call(getClient);
    if (!queryString) {
      throw Error('queryString not provided');
    }
    if (mixPanelAction) {
      yield call(mixPanelAction, { options: props });
    }
    const response = yield call(client.mutate, {
      mutation: gql(queryString),
      variables: spreadPayload ? { ...payload } : { payload },
    });

    const responseData = yield call(get, response?.data, key);
    if (responseQuery) {
      // if (callback?.onSuccess) {
      //   yield call(callback.onSuccess);
      // }
      // yield call(listenActionResponse, { ...props, response_id: responseData });
    } else if (key && response && response.data && get(response.data, key)) {
      if (get(response.data, `${key}.error`)) {
        throw Error(get(response.data, `${key}.error`));
      }
      // if (get(response.data, `${key}.failure_count`)) {
      //   throw Error(`Having failure count: ${get(response.data, `${key}.failure_count`)}`);
      // }
      if (get(response.data, `${key}.error_count`)) {
        if (get(response.data, `${key}.message`)) {
          throw Error(get(response.data, `${key}.message`));
        } else {
          throw Error(`Having failure count: ${get(response.data, `${key}.error_count`)}`);
        }
      }
      if (responseData && Array.isArray(responseData) && responseData.length === 1 && responseData[0]?.error_message) {
        throw Error(responseData[0]?.error_message);
      }
      if (callback?.onSuccess) {
        yield call(callback.onSuccess, key && get(response?.data, key) ? get(response?.data, key) : response);
      }
      if (refetchActions && Array.isArray(refetchActions) && refetchActions?.length) {
        yield all(refetchActions.map(action => put(action({ forceRefresh: true }))));
      }
    }
  } catch (error) {
    if (callback && callback.onError) {
      yield call(callback.onError, error);
    }
    if (get(error, 'graphQLErrors.0.extensions.code') === 'invalid-jwt') {
      yield put({ type: GLOBAL_ERROR, error });
    }
  }
}

export function* fetchAuthRolesWorker() {
  try {
    const client = yield call(getClient);
    const { data } = yield call(client.query, { query: gql(queries.GET_AUTH_ROLES) });
    if (data?.auth_role) {
      yield put({ type: types.STORE_AUTH_ROLES, payload: data.auth_role });
    } else {
      throw Error('Auth Roles not found');
    }
  } catch (error) {
    console.log('error', error);
  }
}

export function* fetchAuthRolesWatcher() {
  yield takeLatest(types.FETCH_AUTH_ROLES, fetchAuthRolesWorker);
}

export function* actionWatcher() {
  yield takeLatest([types.CREATE_AUTH_USER, types.FEATURE_REQUEST, types.REPORT_ISSUE], actionReceiverSaga);
}

export default function* actionSaga() {
  yield all([fork(fetchAuthRolesWatcher), fork(actionWatcher)]);
}
