import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { useAuthState, useSignInWithEmailAndPassword } from 'react-firebase-hooks/auth';

import Loading from 'components/pages/Loading';
import Apollo from 'utils/apollo';

import { auth, signOut } from 'utils/firebase';
import Auth from 'utils/auth';

const UserAuthContext = React.createContext({
  client: {},
  user: {},
});

function UserAuthProvider({ children }) {
  const [currentUser, loading] = useAuthState(auth);
  const [signInWithEmailAndPassword] = useSignInWithEmailAndPassword(auth);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isAuthenticating, setIsAuthenticating] = useState(true);
  const [client, setClient] = useState({});

  const login = async (username, password, tenant) => {
    if (tenant) {
      auth.tenantId = tenant;
    }
    await signInWithEmailAndPassword(username, password);
  };

  const logout = async () => {
    await Auth.logout();
    await signOut(auth);
  };

  const createClient = async () => {
    if (!currentUser) {
      return;
    }
    const graphToken = await currentUser?.getIdToken(true);

    Apollo.removeClient();
    const c = await Apollo.createClient({
      graphToken,
    });
    setClient(c);
  };

  useEffect(() => {
    if (currentUser && currentUser?.accessToken && !loading) {
      Auth?.processToken(currentUser?.accessToken).then(() => {
        setIsAuthenticated(true);
        setIsAuthenticating(false);
        return createClient();
      });
    } else if (!loading) {
      setIsAuthenticating(false);
      setIsAuthenticated(false);
    }
  }, [currentUser, loading]);

  const value = useMemo(
    () => ({
      user: auth.currentUser,
      client,
      isAuthenticated,
      isAuthenticating,
      login,
      logout,
    }),
    [isAuthenticated, client, isAuthenticating],
  );

  if (loading) {
    return <Loading />;
  }
  return <UserAuthContext.Provider value={value}>{children}</UserAuthContext.Provider>;
}

UserAuthProvider.propTypes = {
  children: PropTypes.any,
};

export { UserAuthProvider, UserAuthContext };
