import { createSelector } from 'reselect';
import { initialState } from './reducer';

// const selectGlobal = (state) => state.global || initialState;
const selectRouter = state => state.router;

const selectApp = state => state || initialState;

const selectTasks = state => state || [];

const selectSaved = state => state || {};

const selectError = state => state || {};

const makeSelectLocation = () => createSelector(selectRouter, routerState => routerState.location);

const makeSelectError = () => createSelector(selectError, state => state.error);

const makeSelectTasks = () => createSelector(selectTasks, state => state);

const makeSelectApp = () => createSelector(selectApp, state => state);

const makeSelectSaved = () => createSelector(selectSaved, state => state);
export { makeSelectLocation, makeSelectTasks, makeSelectError, makeSelectApp, makeSelectSaved };
