/*
 * AppReducer
 *
 * The reducer takes care of our data. Using actions, we can
 * update our application state. To add a new action,
 * add it to the switch statement in the reducer function
 *
 */

import produce from 'immer';
import { concat, remove, set } from 'lodash';
import { setItem } from 'utils/state';

import {
  GLOBAL_ERROR,
  GLOBAL_LOADING,
  INIT_CLIENT,
  SAVE_STATE_ACTION,
  CLEAR_ERROR,
  STOPPED_TASK,
  SET_TASK_ACTIVE,
  INIT_COMPLETE,
} from './constants';

// The initial state of the App
export const initialState = {
  loading: true,
  client: {},
  user: {},
  hasClient: false,
};

/* eslint-disable default-case, no-param-reassign */
export const appReducer = (state = initialState, action) =>
  produce(state, draft => {
    switch (action.type) {
      case INIT_CLIENT:
        draft.env = { ...draft.env, ...{ vw: action.vw, vh: action.vh } };
        draft.loading = true;
        break;
      case INIT_COMPLETE:
        draft.loading = false;
        draft.client = action.client;
        draft.user = action.user;
        break;
      case GLOBAL_LOADING:
        draft.loading = true;
        break;
    }
  });

export const savedReducer = (state = {}, action) =>
  produce(state, draft => {
    switch (action.type) {
      case SAVE_STATE_ACTION:
        set(draft, `${action.key}`, action.data);
        setItem(action.key, action.data);
        break;
    }
  });
export const errorReducer = (state = {}, action) =>
  produce(state, draft => {
    switch (action.type) {
      case GLOBAL_ERROR:
        draft.loading = false;
        draft.message = action.e?.message;
        draft.stack = action.e?.stack;
        draft.id = action.e?.id;
        break;
      case CLEAR_ERROR:
        draft.loading = false;
        draft.message = null;
        draft.id = null;
        draft.route = action.route;
        break;
    }
  });

export const taskReducer = (state = [], action) => {
  switch (action.type) {
    case SET_TASK_ACTIVE:
      state = concat(state, [action.task]);
      break;
    case STOPPED_TASK:
      state = remove(state, t => t.id === action.task.id);
      break;
  }
  return state;
};

export default appReducer;
