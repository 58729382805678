import { GLOBAL_LOADING, GLOBAL_ERROR, INIT_CLIENT, CLEAR_ERROR, SAVE_STATE_ACTION } from './constants';

/**
 * Global Loading state, this action starts the request saga
 *
 * @return {object} An action object with a type of GLOBAL_LOADING
 */
export function loading() {
  return {
    type: GLOBAL_LOADING,
  };
}

/**
 * Dispatched when there is fatal non-recoverable error
 *
 * @param  {object} e The error object
 *
 * @return {object}      An action object with a type of GLOBAL_ERROR passing the error
 */
export function errorAction(e) {
  return {
    type: GLOBAL_ERROR,
    e,
  };
}

export function clearErrorAction(route) {
  return {
    type: CLEAR_ERROR,
    route,
  };
}

export function saveAction({ key, data }) {
  return {
    type: SAVE_STATE_ACTION,
    key,
    data,
  };
}
export function initializeAction() {
  return {
    type: INIT_CLIENT,
    loading: true,
  };
}
